import axios from "axios";
import {message} from '@/utils/discreteApi'
import router from "@/router";
//const baseUrl = 'http://localhost:9980/app-server';
const baseUrl = 'https://cyberharbor.cn/app-server';

const $http = axios.create({
    baseURL: baseUrl,
    timeout: 15000
});

$http.interceptors.request.use(
    config => {
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

$http.interceptors.response.use(
    (response: any): any => {
        // console.log('response:' + JSON.stringify(response));
        if (response.status == 200) {
            return Promise.resolve(response);
        } else if (response.status == 401) {
            message.error("您没有权限")
            sessionStorage.removeItem("token")
            const router = require("@/router/index")
            router.default.push('/login')
            return Promise.reject(response);
        } else {
            return Promise.reject(response);
        }
    },
    error => {
        if (error.response.status == 401) {
            message.error("您没有权限")
            sessionStorage.removeItem("token")
            const router = require("@/router/index")
            router.default.push('/login')
        } else {
            if (error.response.data?.errorMsg) {
                message.error(error.response.data?.errorMsg)
            } else {
                message.error('服务器繁忙，请刷新后重试！')
            }
        }
        return Promise.reject(error);
    }
);


/**
 *
 * @param url
 * @param data
 * @returns
 */
export function post(url: string, data?: any, params?: any) {
    const headers: any = {}
    const token = sessionStorage.getItem("token")
    if (token)
        headers.Authorization = token
    return new Promise((resolve, reject) => {
        $http({
            method: 'post',
            url,
            data: data,
            params: params,
            headers: headers
        })
            .then((res: any) => {
                resolve(res)
            })
            .catch((err: any) => {
                reject(err)
            });
    })
}

/**
 *
 * @param url
 * @param data
 * @returns
 */
export function get(url: string, data?: any) {
    const headers: any = {}
    const token = sessionStorage.getItem("token")
    if (token)
        headers.Authorization = token
    return new Promise((resolve, reject) => {
        $http({
            method: 'get',
            url,
            params: data,
            headers: headers
        })
            .then((res: any) => {
                resolve(res)
            })
            .catch((err: any) => {
                reject(err)
            })
    })
}

/**
 *
 * @param url
 * @param data
 * @param file_name
 * @param callback1
 * @returns
 */
export function downloadPost(url: string, data: any, file_name: string, callbackProgress: Function | null) {
    const headers: any = {}
    const token = sessionStorage.getItem("token")
    if (token)
        headers.Authorization = token
    return new Promise((resolve, reject) => {
        $http({
            url,
            method: 'post',
            params: data,
            responseType: 'blob',
            timeout: 360000,
            headers: headers,
            onDownloadProgress: function (progressEvent: any) {
                if (callbackProgress) {
                    callbackProgress(progressEvent)
                }
            }
        }).then((res: any) => {
            let blob = new Blob([res.data], {type: `text/plain;charset=utf-8`});
            let fileURL = window.URL.createObjectURL(blob);
            let fileLink = document.createElement('a');
            fileLink.style.zIndex = '-1';
            fileLink.style.position = 'absolute';
            fileLink.href = fileURL;
            fileLink.setAttribute('download', file_name);
            document.body.appendChild(fileLink);
            fileLink.click();
            document.body.removeChild(fileLink);
            resolve(res.data)
        }).catch(error => {
            reject(error)
        })
    })
}

/**
 *
 * @param url
 * @param data
 * @param callback1
 * @returns
 */
export function upload(url: string, data: any[], params: any, callbackProgress: Function | null) {
    const formData = new FormData();
    Array.from(data).forEach((item: any) => {
        formData.append(item.key, item.value)
    });
    const headers: any = {'Content-type': 'multipart/form-data'}
    const token = sessionStorage.getItem("token")
    if (token)
        headers.Authorization = token
    return new Promise((resolve, reject) => {
        $http({
            url,
            method: 'post',
            data: formData,
            timeout: 360000,
            headers: headers,
            params: params,
            onUploadProgress: function (progressEvent: any) {
                if(callbackProgress){
                    callbackProgress(progressEvent)
                }
            }
        }).then((res: any) => {
            resolve(res.data)
        }).catch(error => {
            reject(error)
        })
    })
}


/**
 *
 * @param url
 * @param data
 * @param token
 * @returns
 */
export function post2(url: string, data?: any, token?: string) {
    const headers: any = {Authorization: token}
    return new Promise((resolve, reject) => {
        $http({
            method: 'post',
            url,
            data: data,
            headers: headers
        })
            .then((res: any) => {
                resolve(res)
            })
            .catch((err: any) => {
                reject(err)
            });
    })
}

export function getBaseUrl() {
    return baseUrl;
}